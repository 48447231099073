import React from 'react';
import { Container } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import { useFunctions, useRefs } from '../hooks';
import { Template_fullscreenWrapper } from '../wrappers';

import { useLocation } from 'react-router-dom';
import { getLocationParams } from '../util';

// Custom imports:
import { Group, Image, Text, Button, Carousel, Input } from '../components';
import { useEffect } from 'react';
import me from '../assets/templates/me.jpg';
import calmcarminelizard2draft_926e57134e638aec4386c140b11ae62b from '../assets/nfts/calmcarminelizard2draft_926e57134e638aec4386c140b11ae62b.png';
import handsomecoffeecatdraft_2595202ac6ca30678ec4d851bd9ccd4b from '../assets/nfts/handsomecoffeecatdraft_2595202ac6ca30678ec4d851bd9ccd4b.png';
import alertgraphiteviperdraft_0d8ce682913d18429e04f67dc745abf4 from '../assets/nfts/alertgraphiteviperdraft_0d8ce682913d18429e04f67dc745abf4.png';
import atomictortillastalliondraft_1cd6344b8d0d3d18d0022cf1948632d1 from '../assets/nfts/atomictortillastalliondraft_1cd6344b8d0d3d18d0022cf1948632d1.png';
import futureultravioletpigdraft_64bc5eb7d1805067b9a43800575941c9 from '../assets/nfts/futureultravioletpigdraft_64bc5eb7d1805067b9a43800575941c9.png';
import dizzystonerooster3draft_c7ec309a2e972ea845aefaac9d3b5242 from '../assets/nfts/dizzystonerooster3draft_c7ec309a2e972ea845aefaac9d3b5242.png';
import electricopalmantiswebsite_98ee5cbb755c3a0bf1fa47d026e81717 from '../assets/nfts/electricopalmantiswebsite_98ee5cbb755c3a0bf1fa47d026e81717.png';
import separator_white from '../assets/templates/separator_white.svg';
import how_image from '../assets/templates/how_image.png';
import separator from '../assets/templates/separator.svg';
import samsung_frame from '../assets/templates/samsung_frame.png';
import template_screenshot from '../assets/templates/template_screenshot.png';
import triangle from '../assets/templates/triangle.png';
import home_banner from '../assets/templates/home_banner.jpg';
import { useTranslation } from 'react-i18next';

export const Home_1View = () => {
  const { run } = useFunctions();
  const { search, pathname } = useLocation();


  // Custom exports:
	const { t } = useTranslation();

  // Custom functions:
	const home_1Params = () => {
		run( getLocationParams( search, pathname ) )
	}
	useEffect( home_1Params, [])
	const home_1OnLoaded = (options) => {
		run([{"id":"set1681782156768","function":"set","what":"@property.minerName","value":"default"},{"id":"set1681782156768","function":"set","what":"@property.emailAddress","value":"default"},{"id":"set1681782156768","function":"set","what":"@property.preferredContact","value":"default"}], options)
	}
	useEffect(home_1OnLoaded, [])
	const buttonCommisson1680479652012OnClick = (options) => {
		run([{"id":"api1680479652012","function":"api","api":{"method":"sendgrid.sendTemplate","template_id":"d-9497f8db23ba4a37944cbead089f6777","to":"thehermanfamily@gmail.com","from":"cmh3studio@gmail.com","dynamic_template_data":{"email":"@property.emailAddress","miner":"@property.minerName","name":"@property.preferredContact","referral":"@property.referralCode"}},"into":"@property.result","onsuccess":[{"id":"popup1680479652012","function":"popup","dialog":"test1"},{"id":"goto1680479802855","function":"goto","view":"home"}],"onerror":[{"id":"popup1680479652012","function":"popup","dialog":"test2"}],"if":{"what":"@property.emailAddress","is_not":"default","and":{"what":"@property.minerName","is_not":"default","and":{"what":"@property.preferredContact","is_not":"default"}}}}], options)
	}
	const miner_name1680479652012OnClick = (options) => {
		run([{"id":"set1681783448314","function":"set","what":"@property.minerName","value":null}], options)
	}
	const client_email1680479652012OnClick = (options) => {
		run([{"id":"set1681783417374","function":"set","what":"@property.emailAddress","value":null}], options)
	}
	const client_name1680479652012OnClick = (options) => {
		run([{"id":"set1681783374463","function":"set","what":"@property.preferredContact","value":null}], options)
	}
	const homeBannerInfoMoreButton1680479652012OnClick = (options) => {
		run([{"function":"scroll","to":"@element.homeFeatures1680479652012"}], options)
	}
	const homeBannerCommissionButton1680479652012OnClick = (options) => {
		run([{"function":"scroll","to":"@element.homeDownload1680479652013"}], options)
	}

  return (
    <>
      <Helmet>
				<title>{t('home_1.title')}</title>
      </Helmet>
      <Template_fullscreenWrapper>
          
			<Group
				id="mainBody1680479652013"
				direction="vertical"
				className="w-100 h-fill position-relative scrollable" >
				<Group
					id="homeBanner1680479652012"
					direction="horizontal"
					className="max-w-100 w-100 h-auto position-relative" >
					<Image
						id="homeBannerImage1680479652012"
						src={home_banner}
						className="w-100 min-h-100 h-100 position-absolute cover" />
					<Group
						id="homeBannerDarken1680479652012"
						direction="auto"
						className="w-100 min-h-100 h-100 position-absolute bg-" >
					</Group>
					<Image
						id="homeBannerImage1680479652012"
						src={triangle}
						className="w-100 min-h-200px h-200px valign-end position-absolute-bottom mb--1px fill" />
					<Group
						id="homeBannerContent1680479652012"
						direction="auto"
						className="w-100 min-h-103vh h-auto position-relative" >
						<Group
							id="homeBannerInfoContent1680479652012"
							direction="vertical"
							className="min-w-300px w-fill h-auto align-start valign-center position-relative ps-10 pe-40 py-48px" >
							<Text
								id="homeBannerInfoTitle1680479652012"
								tag="h1"
								className="w-100 h-auto position-relative text-primary-text ff-home" >
								{t('home_1.homeBannerInfoTitle1680479652012')}
							</Text>
							<Text
								id="homeBannerInfoDescription1680479652012"
								className="w-100 h-auto position-relative text-white ff-home" >
								{t('home_1.homeBannerInfoDescription1680479652012')}
							</Text>
							<Group
								id="homeBannerInfoButtons1680479652012"
								direction="auto"
								className="w-100 h-auto position-relative mt-32px" >
								<Button
									id="homeBannerCommissionButton1680479652012"
									onClick={homeBannerCommissionButton1680479652012OnClick}
									variant="text-on-primary"
									className="w-auto h-auto border-radius-50px position-relative me-16px mb-16px" >
									{t('home_1.homeBannerCommissionButton1680479652012')}
								</Button>
								<Button
									id="homeBannerInfoMoreButton1680479652012"
									onClick={homeBannerInfoMoreButton1680479652012OnClick}
									variant="primary-light"
									className="w-auto h-auto border-radius-50px position-relative mb-16px" >
									{t('home_1.homeBannerInfoMoreButton1680479652012')}
								</Button>
							</Group>
						</Group>
						<Group
							id="homeBannerPhone1680479652012"
							direction="horizontal"
							className="max-w-500px min-w-300px w-fill h-auto align-end valign-end position-relative pe-8 mb-64px" >
							<Group
								id="phoneScreenshotWrapper1680479652012"
								direction="vertical"
								className="w-320px h-auto border-radius-30px position-absolute p-1" >
								<Image
									id="image1680479677617"
									src={template_screenshot}
									className="w-100 min-h-650px h-650px border-radius-30px position-relative" />
							</Group>
							<Image
								id="phoneFrame1680479652012"
								src={samsung_frame}
								className="w-320px h-fill position-relative" />
						</Group>
					</Group>
				</Group>
				<Group
					id="homeOverview1680479652012"
					direction="vertical"
					className="max-w-1200px w-100 h-auto align-center position-relative p-32px" >
					<Text
						id="homeOverviewTitle1680479652012"
						tag="h1"
						className="w-auto h-auto align-center position-relative ff-home text-center" >
						{t('home_1.homeOverviewTitle1680479652012')}
					</Text>
					<Image
						id="homeOverviewSeparator1680479652012"
						src={separator}
						className="w-82px h-auto align-center position-relative mb-32px" />
				</Group>
				<Group
					id="homeHighlights1680479652012"
					direction="vertical"
					className="max-w-1600px w-100 h-auto align-center position-relative p-32px mt-48px" >
					<Image
						id="image1681785589187"
						src={how_image}
						className="w-100 h-auto position-relative" />
				</Group>
				<Group
					id="homeRecentCreations1680479652012"
					direction="vertical"
					className="w-100 h-auto align-center position-relative mt-64px" >
					<Group
						id="homeRecentCreationsContentGroup1680479652012"
						direction="vertical"
						className="w-100 h-auto position-relative bg-primary-to-primary-dark p-32px" >
						<Text
							id="homeRecentCreationsTitle1680479652012"
							tag="h1"
							className="w-auto h-auto align-center position-relative text-secondary-text ff-home text-center" >
							{t('home_1.homeRecentCreationsTitle1680479652012')}
						</Text>
						<Image
							id="homeHowWorksSeparator1680479652012"
							src={separator_white}
							className="w-72px h-auto align-center position-relative mt-16px mb-32px" />
						<Group
							id="homeHowWorksStepsGroup1680479652012"
							direction="auto"
							className="w-100 h-auto position-relative" >
							<Group
								id="groupCarousel1680479652012"
								direction="vertical"
								className="w-100 h-auto align-center position-relative" >
								<Carousel
									id="carousel1680479652012"
									className="max-w-500px w-65 h-auto align-center position-relative" >
									<Carousel.Item>
										<Image
											id="carousel1680479652012-0"
											src={electricopalmantiswebsite_98ee5cbb755c3a0bf1fa47d026e81717}
											className="w-100 h-auto position-relative cover" />
										<Carousel.Caption>
											<h3>{t('home_1.carousel1680479652012.0.title')}</h3>
										</Carousel.Caption>
									</Carousel.Item>
									<Carousel.Item>
										<Image
											id="carousel1680479652012-1"
											src={dizzystonerooster3draft_c7ec309a2e972ea845aefaac9d3b5242}
											className="w-100 h-auto position-relative cover" />
										<Carousel.Caption>
											<h3>{t('home_1.carousel1680479652012.1.title')}</h3>
										</Carousel.Caption>
									</Carousel.Item>
									<Carousel.Item>
										<Image
											id="carousel1680479652012-2"
											src={futureultravioletpigdraft_64bc5eb7d1805067b9a43800575941c9}
											className="w-100 h-auto position-relative cover" />
										<Carousel.Caption>
											<h3>{t('home_1.carousel1680479652012.2.title')}</h3>
										</Carousel.Caption>
									</Carousel.Item>
									<Carousel.Item>
										<Image
											id="carousel1680479652012-3"
											src={atomictortillastalliondraft_1cd6344b8d0d3d18d0022cf1948632d1}
											className="w-100 h-auto position-relative cover" />
										<Carousel.Caption>
											<h3>{t('home_1.carousel1680479652012.3.title')}</h3>
										</Carousel.Caption>
									</Carousel.Item>
									<Carousel.Item>
										<Image
											id="carousel1680479652012-4"
											src={alertgraphiteviperdraft_0d8ce682913d18429e04f67dc745abf4}
											className="w-100 h-auto position-relative cover" />
										<Carousel.Caption>
											<h3>{t('home_1.carousel1680479652012.4.title')}</h3>
										</Carousel.Caption>
									</Carousel.Item>
									<Carousel.Item>
										<Image
											id="carousel1680479652012-5"
											src={handsomecoffeecatdraft_2595202ac6ca30678ec4d851bd9ccd4b}
											className="w-100 h-auto position-relative cover" />
										<Carousel.Caption>
											<h3>{t('home_1.carousel1680479652012.5.title')}</h3>
										</Carousel.Caption>
									</Carousel.Item>
									<Carousel.Item>
										<Image
											id="carousel1680479652012-6"
											src={calmcarminelizard2draft_926e57134e638aec4386c140b11ae62b}
											className="w-100 h-auto position-relative cover" />
										<Carousel.Caption>
											<h3>{t('home_1.carousel1680479652012.6.title')}</h3>
										</Carousel.Caption>
									</Carousel.Item>
								</Carousel>
							</Group>
						</Group>
					</Group>
				</Group>
				<Group
					id="homeFeatures1680479652012"
					direction="vertical"
					className="max-w-1200px w-100 h-auto align-center position-relative p-32px" >
					<Text
						id="homeFeaturesTitle1680479652012"
						tag="h1"
						className="w-auto h-auto align-center position-relative ff-home text-center" >
						{t('home_1.homeFeaturesTitle1680479652012')}
					</Text>
					<Image
						id="homeFeaturesSeparator1680479652012"
						src={separator}
						className="w-82px h-auto align-center position-relative mb-32px" />
					<Text
						id="homeFeaturesDescription1680479652012"
						className="w-85 h-auto align-center position-relative ff-home text-center" >
						{t('home_1.homeFeaturesDescription1680479652012')}
					</Text>
					<Image
						id="image1680479652012"
						src={me}
						className="w-500px h-auto border-radius-30px align-center position-relative" />
					<Text
						id="homeFeaturesDescription1680479652012"
						className="w-85 h-auto align-center position-relative ff-home text-center" >
						{t('home_1.homeFeaturesDescription1680479652012')}
					</Text>
				</Group>
				<Group
					id="homeDownload1680479652013"
					direction="vertical"
					className="w-100 h-auto align-center position-relative mt-64px" >
					<Group
						id="homeDownloadContentGroup1680479652012"
						direction="vertical"
						className="w-100 h-auto border-top border-bottom border-start border-end position-relative bg-primary-text p-32px" >
						<Text
							id="homeDownloadTitle1680479652012"
							tag="h1"
							className="w-auto h-auto align-center position-relative text-secondary-text ff-home text-center" >
							{t('home_1.homeDownloadTitle1680479652012')}
						</Text>
						<Image
							id="homeDownloadSeparator1680479652012"
							src={separator_white}
							className="w-72px h-auto align-center position-relative mt-16px mb-32px" />
						<Text
							id="homeDownloadDescription1680479652012"
							className="max-w-700px w-auto h-auto align-center position-relative m-24px text-black ff-home text-center" >
							{t('home_1.homeDownloadDescription1680479652012')}
						</Text>
						<Group
							id="newFormGroup1680479652012"
							direction="vertical"
							className="w-400px h-auto align-center position-relative bg-primary-light-to-primary-dark p-10px mb-32px" >
							<Group
								id="group1680479652012"
								direction="vertical"
								className="w-69 h-auto align-center position-relative p-20px" >
								<Input.Text
									id="client_name1680479652012"
									onClick={client_name1680479652012OnClick}
									label={t('home_1.client_name1680479652012.label')}
									name="preferredContact"
									src="@property.preferredContact"
								placeholder={t('home_1.client_name1680479652012.hint')}
								autoComplete="off"
									className="w-auto h-auto position-relative" />
								<Input.Email
									id="client_email1680479652012"
									onClick={client_email1680479652012OnClick}
									label={t('home_1.client_email1680479652012.label')}
									name="emailAddress"
									src="@property.emailAddress"
								placeholder={t('home_1.client_email1680479652012.hint')}
								autoComplete="off"
									className="w-auto h-auto position-relative" />
								<Input.Text
									id="miner_name1680479652012"
									onClick={miner_name1680479652012OnClick}
									label={t('home_1.miner_name1680479652012.label')}
									name="minerName"
									src="@property.minerName"
								placeholder={t('home_1.miner_name1680479652012.hint')}
								autoComplete="off"
									className="w-auto h-auto position-relative" />
								<Input.Text
									id="referral_code1680479652012"
									label={t('home_1.referral_code1680479652012.label')}
									name="referralCode"
									src="@property.referralCode"
								placeholder={t('home_1.referral_code1680479652012.hint')}
								autoComplete="off"
									className="w-auto h-auto position-relative" />
								<Button
									id="buttonCommisson1680479652012"
									onClick={buttonCommisson1680479652012OnClick}
									variant="primary"
									className="w-auto h-auto align-center position-relative mt-20px" >
									{t('home_1.buttonCommisson1680479652012')}
								</Button>
							</Group>
						</Group>
					</Group>
				</Group>
				<Group
					id="homeFooter1680479652013"
					direction="vertical"
					className="w-100 h-auto position-relative bg-primary-text p-16px" >
					<Text
						id="text1680479652013"
						tag="h2"
						src="result"
						className="w-50px max-h-100 h-auto align-center position-relative text-secondary-text ff-renner" >
						{t('home_1.text1680479652013')}
					</Text>
				</Group>
			</Group>
      </Template_fullscreenWrapper>
    </>
  );
};

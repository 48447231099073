import React from 'react';

// Custom imports:
import Test1Dialog from './Test1Dialog';
import Test2Dialog from './Test2Dialog';



export const Dialogs = () => {
  return (
    <>
      
			<Test1Dialog />
			<Test2Dialog />
      
    </>
  );
};

export default Dialogs;

import React from 'react';
import { Container } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import { useFunctions, useRefs } from '../hooks';
import { Template_fullscreenWrapper } from '../wrappers';

import { useLocation } from 'react-router-dom';
import { getLocationParams } from '../util';

// Custom imports:
import { Group, Image, Text, Button, Carousel, Input } from '../components';
import { useEffect } from 'react';
import me from '../assets/templates/me.jpg';
import calmcarminelizard2draft_926e57134e638aec4386c140b11ae62b from '../assets/nfts/calmcarminelizard2draft_926e57134e638aec4386c140b11ae62b.png';
import handsomecoffeecatdraft_2595202ac6ca30678ec4d851bd9ccd4b from '../assets/nfts/handsomecoffeecatdraft_2595202ac6ca30678ec4d851bd9ccd4b.png';
import alertgraphiteviperdraft_0d8ce682913d18429e04f67dc745abf4 from '../assets/nfts/alertgraphiteviperdraft_0d8ce682913d18429e04f67dc745abf4.png';
import atomictortillastalliondraft_1cd6344b8d0d3d18d0022cf1948632d1 from '../assets/nfts/atomictortillastalliondraft_1cd6344b8d0d3d18d0022cf1948632d1.png';
import futureultravioletpigdraft_64bc5eb7d1805067b9a43800575941c9 from '../assets/nfts/futureultravioletpigdraft_64bc5eb7d1805067b9a43800575941c9.png';
import dizzystonerooster3draft_c7ec309a2e972ea845aefaac9d3b5242 from '../assets/nfts/dizzystonerooster3draft_c7ec309a2e972ea845aefaac9d3b5242.png';
import electricopalmantiswebsite_98ee5cbb755c3a0bf1fa47d026e81717 from '../assets/nfts/electricopalmantiswebsite_98ee5cbb755c3a0bf1fa47d026e81717.png';
import separator_white from '../assets/templates/separator_white.svg';
import how_image from '../assets/templates/how_image.png';
import separator from '../assets/templates/separator.svg';
import samsung_frame from '../assets/templates/samsung_frame.png';
import replace from '../assets/templates/replace.png';
import triangle from '../assets/templates/triangle.png';
import home_banner from '../assets/templates/home_banner.jpg';
import { useTranslation } from 'react-i18next';

export const HomeView = () => {
  const { run } = useFunctions();
  const { search, pathname } = useLocation();


  // Custom exports:
	const { t } = useTranslation();

  // Custom functions:
	const homeParams = () => {
		run( getLocationParams( search, pathname ) )
	}
	useEffect( homeParams, [])
	const homeOnLoaded = (options) => {
		run([{"id":"set1681781755945","function":"set","what":"@property.minerName","value":"default"},{"id":"set1681781755945","function":"set","what":"@property.emailAddress","value":"default"},{"id":"set1681781755945","function":"set","what":"@property.preferredContact","value":"default"}], options)
	}
	useEffect(homeOnLoaded, [])
	const buttonCommissonOnClick = (options) => {
		run([{"id":"api1680051423701","function":"api","api":{"method":"sendgrid.sendTemplate","template_id":"d-9497f8db23ba4a37944cbead089f6777","to":"thehermanfamily@gmail.com","from":"cmh3studio@gmail.com","dynamic_template_data":{"email":"@property.emailAddress","miner":"@property.minerName","name":"@property.preferredContact","referral":"@property.referralCode"}},"into":"@property.result","onsuccess":[{"id":"popup1680478571038","function":"popup","dialog":"test1"},{"id":"goto1680479835720","function":"goto","view":"home_1"}],"onerror":[{"id":"popup1680478691320","function":"popup","dialog":"test2"}],"if":{"what":"@property.minerName","is_not":"default","and":{"what":"@property.emailAddress","is_not":"default","and":{"what":"@property.preferredContact","is_not":"default"}}}}], options)
	}
	const miner_nameOnClick = (options) => {
		run([{"id":"set1681782946586","function":"set","what":"@property.minerName","value":null}], options)
	}
	const client_emailOnClick = (options) => {
		run([{"id":"set1681782909707","function":"set","what":"@property.emailAddress","value":null}], options)
	}
	const client_nameOnClick = (options) => {
		run([{"id":"set1681782866125","function":"set","what":"@property.preferredContact","value":null}], options)
	}
	const homeBannerInfoMoreButtonOnClick = (options) => {
		run([{"function":"scroll","to":"@element.homeFeatures"}], options)
	}
	const homeBannerCommissionButtonOnClick = (options) => {
		run([{"function":"scroll","to":"@element.homeDownload"}], options)
	}

  return (
    <>
      <Helmet>
				<title>{t('home.title')}</title>
      </Helmet>
      <Template_fullscreenWrapper>
          
			<Group
				id="mainBody"
				direction="vertical"
				className="w-100 h-fill position-relative scrollable" >
				<Group
					id="homeBanner"
					direction="horizontal"
					className="max-w-100 w-100 h-auto position-relative" >
					<Image
						id="homeBannerImage"
						src={home_banner}
						className="w-100 min-h-100 h-100 position-absolute cover" />
					<Group
						id="homeBannerDarken"
						direction="auto"
						className="w-100 min-h-100 h-100 position-absolute bg-" >
					</Group>
					<Image
						id="homeBannerImage"
						src={triangle}
						className="w-100 min-h-200px h-200px valign-end position-absolute-bottom mb--1px fill" />
					<Group
						id="homeBannerContent"
						direction="auto"
						className="w-100 min-h-103vh h-auto position-relative" >
						<Group
							id="homeBannerInfoContent"
							direction="vertical"
							className="min-w-300px w-fill h-auto align-start valign-center position-relative ps-10 pe-40 py-48px" >
							<Text
								id="homeBannerInfoTitle"
								tag="h1"
								className="w-100 h-auto position-relative text-primary-text ff-home" >
								{t('home.homeBannerInfoTitle')}
							</Text>
							<Text
								id="homeBannerInfoDescription"
								className="w-100 h-auto position-relative text-white ff-home" >
								{t('home.homeBannerInfoDescription')}
							</Text>
							<Group
								id="homeBannerInfoButtons"
								direction="auto"
								className="w-100 h-auto position-relative mt-32px" >
								<Button
									id="homeBannerCommissionButton"
									onClick={homeBannerCommissionButtonOnClick}
									variant="text-on-primary"
									className="w-auto h-auto border-radius-50px position-relative me-16px mb-16px" >
									{t('home.homeBannerCommissionButton')}
								</Button>
								<Button
									id="homeBannerInfoMoreButton"
									onClick={homeBannerInfoMoreButtonOnClick}
									variant="primary-light"
									className="w-auto h-auto border-radius-50px position-relative mb-16px" >
									{t('home.homeBannerInfoMoreButton')}
								</Button>
							</Group>
						</Group>
						<Group
							id="homeBannerPhone"
							direction="horizontal"
							className="max-w-500px min-w-300px w-fill h-auto align-end valign-end position-relative pe-8 mb-64px" >
							<Group
								id="phoneScreenshotWrapper"
								direction="vertical"
								className="w-320px h-auto border-radius-30px position-absolute p-1" >
								<Image
									id="image1685653855627"
									src={replace}
									className="w-100 min-h-650px h-650px border-radius-30px position-relative" />
							</Group>
							<Image
								id="phoneFrame"
								src={samsung_frame}
								className="w-320px h-fill position-relative" />
						</Group>
					</Group>
				</Group>
				<Group
					id="homeOverview"
					direction="vertical"
					className="max-w-1200px w-100 h-auto align-center position-relative p-32px" >
					<Text
						id="homeOverviewTitle"
						tag="h1"
						className="w-auto h-auto align-center position-relative ff-home text-center" >
						{t('home.homeOverviewTitle')}
					</Text>
					<Image
						id="homeOverviewSeparator"
						src={separator}
						className="w-82px h-auto align-center position-relative mb-35px" />
				</Group>
				<Group
					id="homeHighlights"
					direction="vertical"
					className="max-w-1600px w-100 h-auto align-center position-relative p-15px mt-48px" >
					<Image
						id="image1681785154923"
						src={how_image}
						className="w-100 h-auto position-relative" />
				</Group>
				<Group
					id="homeRecentCreations"
					direction="vertical"
					className="w-100 h-auto align-center position-relative mt-64px" >
					<Group
						id="homeRecentCreationsContentGroup"
						direction="vertical"
						className="w-100 h-auto position-relative bg-primary-to-primary-dark p-32px" >
						<Text
							id="homeRecentCreationsTitle"
							tag="h1"
							className="w-auto h-auto align-center position-relative text-secondary-text ff-home text-center" >
							{t('home.homeRecentCreationsTitle')}
						</Text>
						<Image
							id="homeHowWorksSeparator"
							src={separator_white}
							className="w-72px h-auto align-center position-relative mt-16px mb-32px" />
						<Group
							id="homeHowWorksStepsGroup"
							direction="auto"
							className="w-100 h-auto position-relative" >
							<Group
								id="groupCarousel"
								direction="vertical"
								className="w-100 h-auto align-center position-relative" >
								<Carousel
									id="carousel"
									className="max-w-500px w-65 h-auto align-center position-relative" >
									<Carousel.Item>
										<Image
											id="carousel-0"
											src={electricopalmantiswebsite_98ee5cbb755c3a0bf1fa47d026e81717}
											className="w-100 h-auto position-relative cover" />
										<Carousel.Caption>
											<h3>{t('home.carousel.0.title')}</h3>
										</Carousel.Caption>
									</Carousel.Item>
									<Carousel.Item>
										<Image
											id="carousel-1"
											src={dizzystonerooster3draft_c7ec309a2e972ea845aefaac9d3b5242}
											className="w-100 h-auto position-relative cover" />
										<Carousel.Caption>
											<h3>{t('home.carousel.1.title')}</h3>
										</Carousel.Caption>
									</Carousel.Item>
									<Carousel.Item>
										<Image
											id="carousel-2"
											src={futureultravioletpigdraft_64bc5eb7d1805067b9a43800575941c9}
											className="w-100 h-auto position-relative cover" />
										<Carousel.Caption>
											<h3>{t('home.carousel.2.title')}</h3>
										</Carousel.Caption>
									</Carousel.Item>
									<Carousel.Item>
										<Image
											id="carousel-3"
											src={atomictortillastalliondraft_1cd6344b8d0d3d18d0022cf1948632d1}
											className="w-100 h-auto position-relative cover" />
										<Carousel.Caption>
											<h3>{t('home.carousel.3.title')}</h3>
										</Carousel.Caption>
									</Carousel.Item>
									<Carousel.Item>
										<Image
											id="carousel-4"
											src={alertgraphiteviperdraft_0d8ce682913d18429e04f67dc745abf4}
											className="w-100 h-auto position-relative cover" />
										<Carousel.Caption>
											<h3>{t('home.carousel.4.title')}</h3>
										</Carousel.Caption>
									</Carousel.Item>
									<Carousel.Item>
										<Image
											id="carousel-5"
											src={handsomecoffeecatdraft_2595202ac6ca30678ec4d851bd9ccd4b}
											className="w-100 h-auto position-relative cover" />
										<Carousel.Caption>
											<h3>{t('home.carousel.5.title')}</h3>
										</Carousel.Caption>
									</Carousel.Item>
									<Carousel.Item>
										<Image
											id="carousel-6"
											src={calmcarminelizard2draft_926e57134e638aec4386c140b11ae62b}
											className="w-100 h-auto position-relative cover" />
										<Carousel.Caption>
											<h3>{t('home.carousel.6.title')}</h3>
										</Carousel.Caption>
									</Carousel.Item>
								</Carousel>
							</Group>
						</Group>
					</Group>
				</Group>
				<Group
					id="homeFeatures"
					direction="vertical"
					className="max-w-1200px w-100 h-auto align-center position-relative p-32px" >
					<Text
						id="homeFeaturesTitle"
						tag="h1"
						className="w-auto h-auto align-center position-relative ff-home text-center" >
						{t('home.homeFeaturesTitle')}
					</Text>
					<Image
						id="homeFeaturesSeparator"
						src={separator}
						className="w-82px h-auto align-center position-relative mb-32px" />
					<Text
						id="homeFeaturesDescription"
						className="w-85 h-auto align-center position-relative ff-home text-center" >
						{t('home.homeFeaturesDescription')}
					</Text>
					<Image
						id="image1676337522738"
						src={me}
						className="w-500px h-auto border-radius-30px align-center position-relative" />
					<Text
						id="homeFeaturesDescription1676337956628"
						className="w-85 h-auto align-center position-relative ff-home text-center" >
						{t('home.homeFeaturesDescription1676337956628')}
					</Text>
				</Group>
				<Group
					id="homeDownload"
					direction="vertical"
					className="w-100 h-auto align-center position-relative mt-64px" >
					<Group
						id="homeDownloadContentGroup"
						direction="vertical"
						className="w-100 h-auto border-top border-bottom border-start border-end position-relative bg-primary-text p-32px" >
						<Text
							id="homeDownloadTitle"
							tag="h1"
							className="w-auto h-auto align-center position-relative text-secondary-text ff-home text-center" >
							{t('home.homeDownloadTitle')}
						</Text>
						<Image
							id="homeDownloadSeparator"
							src={separator_white}
							className="w-72px h-auto align-center position-relative mt-16px mb-32px" />
						<Text
							id="homeDownloadDescription"
							className="max-w-700px w-auto h-auto align-center position-relative m-24px text-black ff-home text-center" >
							{t('home.homeDownloadDescription')}
						</Text>
						<Group
							id="newFormGroup"
							direction="vertical"
							className="w-400px h-auto align-center position-relative bg-primary-light-to-primary-dark p-10px mb-32px" >
							<Group
								id="group1676693196704"
								direction="vertical"
								className="w-69 h-auto align-center position-relative p-20px" >
								<Input.Text
									id="client_name"
									onClick={client_nameOnClick}
									label={t('home.client_name.label')}
									name="preferredContact"
									src="@property.preferredContact"
								placeholder={t('home.client_name.hint')}
								autoComplete="off"
									className="w-auto h-auto position-relative" />
								<Input.Email
									id="client_email"
									onClick={client_emailOnClick}
									label={t('home.client_email.label')}
									name="emailAddress"
									src="@property.emailAddress"
								placeholder={t('home.client_email.hint')}
								autoComplete="off"
									className="w-auto h-auto border-radius-0px position-relative" />
								<Input.Text
									id="miner_name"
									onClick={miner_nameOnClick}
									label={t('home.miner_name.label')}
									name="minerName"
									src="@property.minerName"
								placeholder={t('home.miner_name.hint')}
								autoComplete="off"
									className="w-auto h-auto position-relative" />
								<Input.Text
									id="referral_code"
									label={t('home.referral_code.label')}
									name="referralCode"
									src="@property.referralCode"
								placeholder={t('home.referral_code.hint')}
								autoComplete="off"
									className="w-auto h-auto position-relative" />
								<Button
									id="buttonCommisson"
									onClick={buttonCommissonOnClick}
									variant="primary"
									className="w-auto h-auto align-center position-relative mt-20px" >
									{t('home.buttonCommisson')}
								</Button>
							</Group>
						</Group>
					</Group>
				</Group>
				<Group
					id="homeFooter"
					direction="horizontal"
					className="w-100 h-auto align-center position-relative bg-primary-text p-16px" >
					<Text
						id="text1679341422649"
						tag="h2"
						src="result"
						className="w-50px max-h-100 h-auto align-center position-relative text-secondary-text ff-renner" >
						{t('home.text1679341422649')}
					</Text>
					<Text
						id="text1681781817307"
						tag="h2"
						src="result"
						className="w-50px max-h-100 h-auto align-center position-relative text-secondary-text ff-renner" >
						{t('home.text1681781817307')}
					</Text>
				</Group>
			</Group>
      </Template_fullscreenWrapper>
    </>
  );
};

import React from 'react';
import { useDialogs } from '../hooks';
import { useFunctions } from '../hooks';

// Custom imports:
import { Modal, Button } from '../components';
import { useTranslation } from 'react-i18next';

const Test1Dialog = () => {
    const { run } = useFunctions();
    // Custom exports:
	const { t } = useTranslation();

    // Custom functions:
	const test1_buttons_positiveOnClick = (options) => {
		run([{"function":"hide","dialog":"test1"}], options)
	}

  return (
    
    
			<Modal id="test1">
					<Modal.Header>
						<Modal.Title>{t('test1.title')}</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						{t('test1.text')}
					</Modal.Body>
					<Modal.Footer>
						<Button
							id="test1_buttons_positive"
							onClick={test1_buttons_positiveOnClick}
							variant="primary"
							className="w-auto h-auto position-relative" >
							{t('test1.test1_buttons_positive')}
						</Button>
					</Modal.Footer>
			</Modal>
    
  );
};

export default Test1Dialog;

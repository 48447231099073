import React from 'react';
import { useDialogs } from '../hooks';
import { useFunctions } from '../hooks';

// Custom imports:
import { Modal, Button } from '../components';
import { useTranslation } from 'react-i18next';

const Test2Dialog = () => {
    const { run } = useFunctions();
    // Custom exports:
	const { t } = useTranslation();

    // Custom functions:
	const test2_buttons_positiveOnClick = (options) => {
		run([{"function":"hide","dialog":"test2"}], options)
	}

  return (
    
    
			<Modal id="test2">
					<Modal.Header>
						<Modal.Title>{t('test2.title')}</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						{t('test2.text')}
					</Modal.Body>
					<Modal.Footer>
						<Button
							id="test2_buttons_positive"
							onClick={test2_buttons_positiveOnClick}
							variant="primary"
							className="w-auto h-auto position-relative" >
							{t('test2.test2_buttons_positive')}
						</Button>
					</Modal.Footer>
			</Modal>
    
  );
};

export default Test2Dialog;
